// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminInspectionPoints,
} from "@containers";

/**
 * Nieuw inspectiepunt
 */
const CreateInspectionPoint = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Nieuw inspectiepunt">
            <AdminInspectionPoints id="beheer-nieuwe-inspectiepunten" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(CreateInspectionPoint);
